<template>
   <CTabs tabs class="nav-underline nav-underline-primary">
        <CTab active>
          <template slot="title">
            <CIcon name="cil-task" />&nbsp;Filtre
          </template>
          <CForm ref="form" style="height: 100vh; padding: 10px 10px 100px; overflow-y: scroll;">
            <CRow>
              <CCol col="6">
                <CInput
                    label="Başlık"
                    horizontal
                    v-model="data.title"
                />
              </CCol>
              <CCol col="3" sm="2">
                <CButton type="submit" size="sm" color="primary" @click="filter">
                  <CIcon name="cil-check-circle"/>
                  Filtrele
                </CButton>
              </CCol>
              <CCol col="3" sm="2">
                <CButton type="reset" size="sm" color="danger" @click="clearFilters">
                  <CIcon name="cil-ban"/>
                  Temizle
                </CButton>
              </CCol>
            </CRow>
            <CRow>
              <CCol>
                <CInput
                    label="Oluşturma Tarihi"
                    horizontal
                    type="date"
                    v-model="data.startTime"
                />
              </CCol>
              <CCol>
                <CInput
                    label="Oluşturma Tarihi"
                    horizontal
                    type="date"
                    v-model="data.endTime"
                />
              </CCol>
              
            </CRow>
            <CRow>
              <CCol>
                <CInput
                    label="Güncelleme Tarihi"
                    horizontal
                    type="date"
                    v-model="data.startUpdateTime"
                />
              </CCol>
              <CCol>
                <CInput
                    label="Güncelleme Tarihi"
                    horizontal
                    type="date"
                    v-model="data.endUpdateTime"
                />
              </CCol>
              
            </CRow>
            <CRow>
              <CCol>
                <CInput
                    label="İçerik"
                    horizontal
                    v-model="data.description"
                />
              </CCol>
              <CCol>
                <CSelect
                  label="Pozisyon"
                  placeholder="Pozisyon"
                  horizontal
                  :options="careerPositions"
                  :value.sync="data.careerPosition"
                >
                </CSelect>
              </CCol>
            </CRow>
            <CRow>
              <CCol>
                <CRow>
                  <CCol>
                    <CSelect
                      label="Aktif"
                      horizontal
                      :options="yesNoList"
                      :value.sync="data.activeFlag"
                    />
                  </CCol>
                  <CCol>
                    <CSelect
                      label="Dil"
                      horizontal
                      :options="langs"
                      :value.sync="data.lang"
                    />
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
          </CForm>
        </CTab>
    </CTabs>
</template>

<script>
   export default{
       name: "JobPostingFilter",
       props:{
           validated: Boolean,
           params: Object,
           actionType: String,
           filter: Function
       },
       computed:{
          langs: function() {
              let data = [{value: undefined, label: 'Dil Seçin'}]
              this.$store.getters.langs.map(r => data.push({value:r.id, label: r.name}))
              return data
          },
          careerPositions: function() {
              let data = [{value: undefined, label: 'Pozisyon Seçin'}]
              this.$store.getters.careerPositions.map(r => data.push({value:r.id, label: r.name}))
              return data
          },
       },
       watch:{
           params: function(val){
               this.data = {...val};
           },
       },
       data: function(){
           return{
               data: this.params ? {...this.params} : {},
               yesNoList: [
                {label: 'Seçiniz', value: ''},
                { label: 'Evet', value: true},
                { label: 'Hayır', value: false},
              ]
           }
       },
       methods:{
            clearFilters: function(){
                this.data = {}
                this.filter()
            }
       }
   }
</script>